import { Box, type SvgIconProps } from '@mui/material'
import AppPageTitle from 'components/app-page-title/AppPageTitle'
import React from 'react'

interface PageTitleProps {
  title: string
  icon?: React.ComponentType<SvgIconProps> | undefined
  children: React.ReactNode
}

const PageBase: React.FC<PageTitleProps> = ({ title, icon, children }): JSX.Element => {
  return (
    <Box p={0} m={0}>
      <AppPageTitle icon={icon ?? undefined} title={title} />
          <Box>
              {children}
          </Box>
    </Box>
  )
}

export default React.memo(PageBase)
