import React from 'react'
import { Delete, Person } from '@mui/icons-material'
import { Avatar, Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import PageBase from 'components/page-title/PageBase'
import { useMutation } from '@tanstack/react-query'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'

const MyProfile: React.FC = (): JSX.Element => {
  useDocumentTitle('FantaHockey | My Profile')
  const { openSnackbar } = useSnackbar()
  const { name, email, getProfileNameFirstLetter, logout } = useFantaHockeyContext()
  const { mutateAsync: deleteYahooFantasyAccount } = useMutation<boolean>({
    mutationKey: [ApiMutationKeys.DeleteYahooAccountMutation],
    mutationFn: async () => {
      return (await ApiMutationService[ApiMutationKeys.DeleteYahooAccountMutation]()).data
    }
  })

  const deleteAccount = React.useCallback(async () => {
    await deleteYahooFantasyAccount()
    openSnackbar('Yahoo account deleted successfully', 'success')
  }, [logout])

  return (
    <PageBase icon={Person} title="My Profile">
      <Grid container spacing={2}>
        <Grid item xs={12}>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ margin: 2, padding: 4 }} elevation={3}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {/* Avatar */}
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt={name}
              >
                {getProfileNameFirstLetter()}
              </Avatar>

              {/* Name & Email */}
              <Box sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="h6" color="text.primary">
                  {name}
                </Typography>
                <Typography variant="subtitle2" color="text.primary">
                  {email}
                </Typography>
              </Box>

              {/* Delete Account Button */}
              <Button
                variant="outlined"
                color="error"
                startIcon={<Delete />}
                onClick={() => { void deleteAccount() }}
              >
                Delete Yahoo Account
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </PageBase>
  )
}

MyProfile.displayName = 'MyProfile'
export default MyProfile
