import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import { type WeeklyFantasyTeamPerformance } from 'features/home/models/weeklyPerformanceModel'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'
import { useEffect } from 'react'

interface HomeData {
  isConnected: boolean
  isLoading: boolean
  topPlayers: {
    data: ConnectedYahooFantasyPlayer[] | undefined
    isLoading: boolean
  }
  weeklyPerformance: {
    data: WeeklyFantasyTeamPerformance[]
    isLoading: boolean
  }
}

export const useHomeData = (): HomeData => {
  const store = useFantaHockeyStore()
  const queryClient = useQueryClient()

  // Query for top fantasy players
  const topPlayersQuery = useQuery<ConnectedYahooFantasyPlayer[]>({
    queryKey: [ApiQueryKeys.GetTopFantasyPlayersQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetTopFantasyPlayersQuery]()).data
    },
    enabled: store.isConnectedToYahooFantasy
  })

  // Query for weekly fantasy team performance
  const weeklyPerformanceQuery = useQuery<WeeklyFantasyTeamPerformance[]>({
    queryKey: [ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery]()).data
    },
    enabled: store.isConnectedToYahooFantasy,
    initialData: []
  })

  // Invalidate queries when component unmounts
  useEffect(() => {
    return () => {
      void queryClient.invalidateQueries({
        queryKey: [
          ApiQueryKeys.GetTopFantasyPlayersQuery,
          ApiQueryKeys.GetWeeklyFantasyTeamPerformanceQuery
        ]
      })
    }
  }, [queryClient])

  return {
    isConnected: store.isConnectedToYahooFantasy,
    isLoading: store.isFetchingConnectionStatus,
    topPlayers: {
      data: topPlayersQuery.data,
      isLoading: topPlayersQuery.isFetching
    },
    weeklyPerformance: {
      data: weeklyPerformanceQuery.data,
      isLoading: weeklyPerformanceQuery.isFetching
    }
  }
}
