import React, { useMemo } from 'react'
import { Box, Grid2, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { EmojiEvents } from '@mui/icons-material'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import PlayerImage from 'components/shared-components/PlayerImage'
import TeamImage from 'components/shared-components/TeamImage'
import ConnectYahooFantasyTeam from 'components/shared-components/ConnectYahooAccount'
import { useHomeData } from '../hooks/useHomeData'

// Function outside the component for better performance
const getTrophyColor = (index: number): string => {
  switch (index) {
    case 0:
      return '#FFD700' // Gold
    case 1:
      return '#C0C0C0' // Silver
    case 2:
      return '#CD7F32' // Bronze
  }
  return '#FFFFFF' // White for others
}

const TopFantasyPlayers: React.FC = () => {
  const { isConnected, isLoading, topPlayers } = useHomeData()

  // Memoize player list to prevent unnecessary re-renders
  const playerList = useMemo(() => {
    return topPlayers.data?.map((player, index) => (
      <Grid2 key={player.name + index} size={{ xs: 12, sm: 12, md: 12 }}>
        <List sx={{ width: '100%' }}>
          <ListItem sx={{ p: 0, my: 1 }}>
            <ListItemAvatar>
              <TeamImage src={player.teamLogoUrl} />
              <PlayerImage src={player.playerHeadshotUrl} />
            </ListItemAvatar>
            <Box mr={2} />
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="text.primary"
                  fontWeight={600}
                >
                  {player.name}
                </Typography>
              } />
            <Box ml={2} />
            <EmojiEvents sx={{ fontSize: 'calc(0.5dvw + 32px)', color: getTrophyColor(index) }} />
          </ListItem>
        </List>
      </Grid2>
    ))
  }, [topPlayers.data])

  if (isLoading) {
    return (
      <Box>
        <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
      </Box>
    )
  }

  if (!isConnected) {
    return <ConnectYahooFantasyTeam />
  }

  if (topPlayers.isLoading) {
    return (
      <Box>
        <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
      </Box>
    )
  }

  return (
    <Grid2 minHeight={350} maxHeight={400} container spacing={1}>
      <Box mt={1} />
      {playerList}
    </Grid2>
  )
}

export default React.memo(TopFantasyPlayers)
