import React from 'react'
import PageBase from 'components/page-title/PageBase'
import { AccessTime, AutoAwesome, AutoAwesomeSharp, AutoMode, Cancel, Info, Insights, Search, SwapHorizRounded } from '@mui/icons-material'
import { Autocomplete, BottomNavigation, BottomNavigationAction, Box, Button, Card, CardContent, CircularProgress, Collapse, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import DynamicIcon from 'components/helpers/DynamicIcon'
import PlayerImage from 'components/shared-components/PlayerImage'
import TeamImage from 'components/shared-components/TeamImage'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { type PlayerSearchResult } from 'features/trade-analyzer/model/playerSearchResult'
import { useDebounce, useDocumentTitle } from '@uidotdev/usehooks'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'
import { type TradeReportViewModel } from 'features/trade-analyzer/model/tradeReportViewModel'
import PlayerFlag from 'components/my-fantasy-team/PlayerFlag'
import ComparativeAnalysis from './components/comparative-analysis/ComparativeAnalysis'
import TradeAnalysis from './components/trade-analysis/TradeAnalysis'
import XFactorDimension from './components/x-factor-dimension/XFactorDimension'
import TradeSuggestions from './components/trade-suggestions/TradeSuggestions'
import { type NavigateOptions, type SetURLSearchParams, type URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'

enum TradeEdgeBottomNavigationTabs {
  TradeEdge = 0,
  TradeSuggestions = 1
}

enum TradeAnalyzerSteps {
  SelectPlayers = 'SelectPlayers',
  AnalyzeTrade = 'AnalyzeTrade',
  EditTrade = 'EditTrade'
}

enum PlayerTradeSide {
  Trade = 'Trade',
  Receive = 'Receive'
}

interface PlayerSearchAutoCompleteProps {
  label?: string
  onPlayerSelectChange: (value: PlayerSearchResult, side: PlayerTradeSide) => void
}

const PlayerSearchAutoComplete: React.FC<PlayerSearchAutoCompleteProps> = ({
  label = 'Search for a player', onPlayerSelectChange: onChange
}) => {
  const [open, setOpen] = React.useState(false)
  const [players, setPlayers] = React.useState<readonly PlayerSearchResult[]>([])
  const [loading, setLoading] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [key, setKey] = React.useState<string>('')
  const query = useDebounce(searchQuery.trim(), 500)
  const theme = useTheme()

  const { refetch: getPlayers } = useQuery<readonly PlayerSearchResult[]>({
    queryKey: [ApiQueryKeys.GetSearchForPlayersQuery, query],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetSearchForPlayersQuery](query)).data
    },
    enabled: open && query.trim().length > 2
  })

  React.useEffect(() => {
    const fetchPlayers = async (): Promise<void> => {
      if (open && query.trim().length > 2) {
        setLoading(true)
        const result = await getPlayers()
        setPlayers(result.data ?? [])
        setLoading(false)
      } else {
        setPlayers([])
      }
    }

    void fetchPlayers()
  }, [query, open, getPlayers])

  const setOptionForPlayer = React.useCallback((player: PlayerSearchResult, side: PlayerTradeSide) => {
    onChange(player, side)
    setKey(Date.now().toString())
    setPlayers([])
    setSearchQuery('')
    setOpen(false)
  }, [onChange])

  return (
    <Autocomplete
      sx={{
        width: '100%',
        maxWidth: 500,
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.contrastText
          }
        },
        '& label.Mui-focused': {
          color: theme.palette.primary.contrastText
        }
      }}
      open={open}
      color='info'
      onOpen={() => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      onInputChange={(_event, newValue) => { setSearchQuery(newValue) }}
      key={key}
      isOptionEqualToValue={(option, value) => option.playerId === value?.playerId}
      getOptionLabel={(option) => option.fullName}
      renderOption={(props, option) => (
        <ListItem {...props} component="li" key={option.playerId} disableGutters onClick={(event) => { event.preventDefault() }}>
          <ListItemAvatar>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <TeamImage height={56} width={56} src={option.teamLogoImageUrl} effect={'opacity'} />
              <PlayerImage height={48} width={48} src={option.headshotImageUrl} effect={'opacity'} />
            </Box>
          </ListItemAvatar>
          <Box mr={2} />
          <ListItemText secondary={<Typography>{option.fullName}</Typography>} />
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Button size='small' variant='text' color='error' onClick={() => { setOptionForPlayer(option, PlayerTradeSide.Trade) }}>Trade</Button>
            <Button size='small' variant='text' color='success' onClick={() => { setOptionForPlayer(option, PlayerTradeSide.Receive) }}>Receive</Button>
          </Box>
        </ListItem>
      )}
      options={players}
      noOptionsText={query?.length > 2 ? 'No player found' : 'Start typing to search a player'}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: <Search />,
            endAdornment: (
              <>
                {loading && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

interface PlayersFromTeamForTradeProps {
  playersFromTeam: PlayerSearchResult[]
  onPlayerRemove: (players: PlayerSearchResult[]) => void
  onPlayerSwitch: (player: PlayerSearchResult) => void
}

const PlayersFromTeamForTrade: React.FC<PlayersFromTeamForTradeProps> = ({ playersFromTeam, onPlayerRemove, onPlayerSwitch }) => {
  const switchPlayer = (player: PlayerSearchResult | undefined): void => {
    if (player === undefined) {
      return
    }
    const switchedPlayer = playersFromTeam.find((p) => p.playerId === player.playerId)
    if (switchedPlayer === undefined) {
      return
    }
    onPlayerSwitch(switchedPlayer)
  }

  const renderPlayerCard = (player: PlayerSearchResult): JSX.Element => (
    <Box key={player.playerId} my={2}>
      <Card
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: 300,
          minWidth: 300,
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: `url(${player.teamLogoImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0,0,0,0.65))'
          }
        }}
      >
        <CardContent
          sx={{
            position: 'relative',
            height: '100%',
            zIndex: 2,
            p: 0
          }}
        >
          <Box
            sx={{
              width: '100%',
              px: 2,
              py: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(4px)',
              color: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              mb={1}
            >
              <PlayerFlag country={player.country} />
              <PlayerImage height={72} width={72} src={player.headshotImageUrl} />
              <Box ml={2} />
              <Typography variant='body1' sx={{ fontWeight: 600 }}>
                #{player.playerNumber} | {player.fullName} | {player.position}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              backgroundColor: (theme) => theme.palette.primary.dark, // or a custom hex: '#1976d2'
              color: '#fff',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              p: 2,
              mb: -4
            }}
          >
            <Tooltip title='Remove Player' placement='bottom'>
              <Button
                size='large'
                variant='text'
                color='error'
                startIcon={<Cancel />}
                onClick={() => { onPlayerRemove(playersFromTeam.filter((p) => p.playerId !== player.playerId)) }
                }
              >
                Remove
              </Button>
            </Tooltip>

            <Tooltip title='Switch Trade Side' placement='bottom'>
              <Button
                size='large'
                variant='text'
                color='inherit'
                startIcon={<SwapHorizRounded />}
                onClick={() => { switchPlayer(playersFromTeam.find((p) => p.playerId === player.playerId)) }
                }
              >
                Switch
              </Button>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )

  return (
    <Box my={2} maxHeight={'100%'} position={'relative'} minHeight={50} display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center'>
      {playersFromTeam.length === 0 && (
        <Typography variant='body1' color='textPrimary'>
          No players selected
        </Typography>
      )}
      <TransitionGroup>
        {playersFromTeam.map(player => <Collapse key={player.playerId}>{renderPlayerCard(player)}</Collapse>)}
      </TransitionGroup>
    </Box>
  )
}

const TradeEdge: React.FC = () => {
  useDocumentTitle('FantaHockey | Trade Edge')

  const [playersToTrade, setPlayersToTrade] = React.useState<PlayerSearchResult[]>([])
  const [playersToReceive, setPlayersToReceive] = React.useState<PlayerSearchResult[]>([])

  const [tradeAnalyzerStep, setTradeAnalyzerStep] = React.useState<string>(TradeAnalyzerSteps.SelectPlayers)
  const [tradeReport, setTradeReport] = React.useState<TradeReportViewModel | undefined>(undefined)
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false)
  const [value, setValue] = React.useState<TradeEdgeBottomNavigationTabs>(TradeEdgeBottomNavigationTabs.TradeEdge)
  const ref = React.useRef<HTMLDivElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const { isPending: isLoadingTrade, mutateAsync: generateTradeReportAsync } = useMutation<TradeReportViewModel>({
    mutationKey: [ApiMutationKeys.BuildFantasyHockeyTradeReport],
    mutationFn: async () => {
      return (await ApiMutationService[ApiMutationKeys.BuildFantasyHockeyTradeReport](
        playersToReceive,
        playersToTrade
      )).data
    }
  })

  React.useEffect(() => {
    const tab = searchParams.get('tab')
    if (tab === 'trade-suggestions') {
      setValue(TradeEdgeBottomNavigationTabs.TradeSuggestions)
    } else if (tab === 'trade-edge') {
      setValue(TradeEdgeBottomNavigationTabs.TradeEdge)
    }
  }, [searchParams])

  React.useEffect(() => {
    setValue(TradeEdgeBottomNavigationTabs.TradeEdge)
    const fetchTradeReport = async (): Promise<void> => {
      switch (tradeAnalyzerStep) {
        case TradeAnalyzerSteps.SelectPlayers:
          setPlayersToReceive([])
          setPlayersToTrade([])
          setTradeReport(undefined)
          break
        case TradeAnalyzerSteps.AnalyzeTrade: {
          if (playersToTrade.length > 0 && playersToReceive.length > 0) {
            const report = await generateTradeReportAsync()
            setTradeReport(report)
          }
          break
        }
      }
    }
    void fetchTradeReport()
  }, [tradeAnalyzerStep, generateTradeReportAsync])

  const allPlayersSelected = React.useMemo(() => playersToTrade.length > 0 && playersToReceive.length > 0, [playersToTrade, playersToReceive])

  const handleClose = (): void => {
    setOpenInfoDialog(false)
  }

  const handlePlayerSelectChange = (player: PlayerSearchResult, side: PlayerTradeSide): void => {
    if (side === PlayerTradeSide.Trade) {
      setPlayersToTrade((prev) => [...prev, player])
    } else {
      setPlayersToReceive((prev) => [...prev, player])
    }
  }

  const handlePlayerRemove = (updatedPlayers: PlayerSearchResult[], side: PlayerTradeSide): void => {
    if (side === PlayerTradeSide.Trade) {
      setPlayersToTrade(updatedPlayers)
    } else {
      setPlayersToReceive(updatedPlayers)
    }
  }

  const handlePlayerSwitch = (player: PlayerSearchResult, side: PlayerTradeSide): void => {
    if (side === PlayerTradeSide.Trade) {
      setPlayersToReceive((prev) => [...prev, player])
      setPlayersToTrade((prev) => prev.filter((p) => p.playerId !== player.playerId))
    } else {
      setPlayersToTrade((prev) => [...prev, player])
      setPlayersToReceive((prev) => prev.filter((p) => p.playerId !== player.playerId))
    }
  }

  const updateQuery: SetURLSearchParams = (nextInit: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit) | undefined, navigateOpts: NavigateOptions | undefined) => {
    setSearchParams(nextInit, navigateOpts)
  }
  const handleUpdateWithCallback = (tab: string): void => {
    updateQuery((prev: string | URLSearchParams | string[][] | Record<string, string> | undefined) => {
      const newParams = new URLSearchParams(prev)
      newParams.set('tab', tab)
      return newParams
    })
  }

  return (
    <PageBase icon={AutoMode} title="Trade Edge">
      {value === TradeEdgeBottomNavigationTabs.TradeEdge && (
      <Box p={1} m={1}>
        <Paper elevation={3} sx={{ p: { xs: 1, md: 2 } }}>
          {tradeAnalyzerStep === TradeAnalyzerSteps.SelectPlayers && (
            <Box>

              <Box my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Button variant="outlined" color='info' onClick={() => { setOpenInfoDialog(true) }}>
                  <Info /> <Box ml={1} />  See How Trade Edge Works
                </Button>
                <TradeEdgeAiInfoDialog
                  open={openInfoDialog}
                  onClose={handleClose}
                />
              </Box>

              <Grid container spacing={2} sx={{ my: 2 }}>
                {/* Players to Trade */}
                <Grid item xs={12} md={12} my={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: { xs: 2, md: 0 } }}>
                    <Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
                      Search
                    </Typography>
                    <PlayerSearchAutoComplete
                      onPlayerSelectChange={handlePlayerSelectChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: { xs: 2, md: 0 } }}
                  >
                    <Typography variant="h6">
                      Trade
                    </Typography>
                    <PlayersFromTeamForTrade
                      playersFromTeam={playersToTrade}
                      onPlayerRemove={(updated) => { handlePlayerRemove(updated, PlayerTradeSide.Trade) }}
                      onPlayerSwitch={(player) => { handlePlayerSwitch(player, PlayerTradeSide.Trade) }}
                    />
                  </Box>
                </Grid>

                {/* Swap Icon (hidden on xs) */}
                <Grid
                  item
                  xs={12}
                  md={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <DynamicIcon
                      fontSizeSm={36}
                      fontSizeMd={48}
                      Icon={SwapHorizRounded}
                      color="inherit"
                    />
                  </Box>
                </Grid>

                {/* Players to Receive */}
                <Grid item xs={12} md={5.5}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: { xs: 2, md: 0 } }}
                  >
                    <Typography variant="h6">
                        Receive
                        </Typography>
                        <PlayersFromTeamForTrade
                          playersFromTeam={playersToReceive}
                          onPlayerRemove={(updated) => { handlePlayerRemove(updated, PlayerTradeSide.Receive) }}
                          onPlayerSwitch={(player) => { handlePlayerSwitch(player, PlayerTradeSide.Receive) }}
                        />
                      </Box>
                  </Grid>

                {/* Analyze Trade Button */}
                <Grid item xs={12} my={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Button
                      sx={{ p: 1, width: { xs: 'auto', sm: 'auto' } }}
                      variant="outlined"
                      color='success'
                      disabled={!allPlayersSelected}
                      onClick={() => {
                        setTradeAnalyzerStep(TradeAnalyzerSteps.AnalyzeTrade)
                      }}
                    >
                      <Insights />
                      <Box ml={1} />
                      Analyze Trade
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {/* Trade Analysis */}
          {tradeAnalyzerStep === TradeAnalyzerSteps.AnalyzeTrade && (
            <Box
              p={{ xs: 1, md: 2 }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* Loading */}
              {isLoadingTrade && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ my: 2 }}
                >
                  <Typography textAlign="center" variant="body1" color="textPrimary">
                  <Typography variant="body1" sx={{ my: 2, color: 'text.secondary', display: 'flex' }}>
                    <AutoAwesome sx={{ color: 'white' }} />  <Box mr={1}/> We are currently analyzing trade
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2, color: 'text.secondary', display: 'flex' }}>
                    <AccessTime sx={{ color: 'white' }} /> <Box mr={1} /> This may take a minute
                    </Typography>
                  </Typography>

                </Box>
              )}

              {/* Trade Report */}
              {tradeReport != null && !isLoadingTrade && (
                <Box sx={{ width: '100%', maxWidth: 800 }}>
                  <Box mt={2} />
                  <Typography
                    textAlign="center"
                    color="textPrimary"
                    variant="h6"
                    sx={{ mb: 2 }}
                  >
                    📊 Trade Analysis Report
                  </Typography>
                  <Typography my={2}>
                    {tradeReport.tradeAnalysis.statisticalAnalysis.analysisText}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                      ➡️ {playersToTrade.length > 1 ? 'Players' : 'Player'} To Trade
                      </Typography>
                      {tradeReport.tradeAnalysis.tradeDetails.incomingPlayersToTeamTradingAway.map(
                        (p) => (
                          <List
                            key={p.fullName}
                            sx={{ width: '100%', mb: 1 }}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <PlayerImage height={48} width={48} src={p.headshotImageUrl} />
                              </ListItemAvatar>
                              <ListItemText primary={p.fullName} secondary={p.position} />
                              <ListItemAvatar>
                                <TeamImage height={48} width={48} src={p.teamLogoImageUrl} />
                              </ListItemAvatar>
                            </ListItem>
                          </List>
                        )
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                      ⬅️ {playersToReceive.length > 1 ? 'Players' : 'Player'} To Receive
                      </Typography>
                      {tradeReport.tradeAnalysis.tradeDetails.outgoingPlayersFromTeamReceiving.map(
                        (p) => (
                          <List
                            key={p.fullName}
                            sx={{ width: '100%', mb: 1 }}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <PlayerImage height={48} width={48} src={p.headshotImageUrl} />
                              </ListItemAvatar>
                              <ListItemText primary={`${p.fullName}`} secondary={p.position} />
                              <ListItemAvatar>
                                <TeamImage height={48} width={48} src={p.teamLogoImageUrl} />
                              </ListItemAvatar>
                            </ListItem>
                          </List>
                        )
                      )}
                    </Grid>
                  </Grid>
                  {tradeReport.tradeAnalysis.statisticalAnalysis.playerMetricsTable?.length > 0 && <> <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                  📈 Player Statistics
                  </Typography>
                    <Box mt={2} />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 320 }} aria-label="trade-analysis">
                        <TableHead sx={{ backgroundColor: 'secondary.main' }}>
                          <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Player Name</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Goals</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Assists</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Points</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>+/-</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PP Points</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>SOG</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>CF%</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>FF%</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>xGoals</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Games Played</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Game Score</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Faceoffs Won</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Faceoffs Lost</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PIM</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Blocks</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tradeReport.tradeAnalysis.statisticalAnalysis.playerMetricsTable.map(
                            (row) => (
                              <TableRow
                                key={row.playerName}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.playerName}
                                </TableCell>
                                <TableCell align="center">{row.goals}</TableCell>
                                <TableCell align="center">{row.assists}</TableCell>
                                <TableCell align="center">{row.points}</TableCell>
                                <TableCell align="center">{row.plusMinus}</TableCell>
                                <TableCell align="center">
                                  {row.powerPlayPoints ?? 'N/A'}
                                </TableCell>
                                <TableCell align="center">{row.shotsOnGoal ?? 'N/A'}</TableCell>
                                <TableCell align="center">
                                  {row.corsiForPercentage ?? 'N/A'}
                                </TableCell>
                                <TableCell align="center">
                                  {row.fenwickForPercentage ?? 'N/A'}
                                </TableCell>
                                <TableCell align="center">{row.xGoals ?? 'N/A'}</TableCell>
                                <TableCell align="center">{row.gamesPlayed}</TableCell>
                                <TableCell align="center">{row.gameScore}</TableCell>
                                <TableCell align="center">{row.faceoffsWon}</TableCell>
                                <TableCell align="center">{row.faceoffsLost}</TableCell>
                                <TableCell align="center">{row.penaltyMinutes}</TableCell>
                                <TableCell align="center">{row.shotsBlockedByPlayer}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer> </>}
                  {tradeReport.tradeAnalysis.statisticalAnalysis.goalieMetricsTable?.length > 0 && <> <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                  📈 Goalie Statistics
                  </Typography>
                    <Box mt={2} />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 320 }} aria-label="trade-analysis">
                        <TableHead sx={{ backgroundColor: 'secondary.main' }}>
                          <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Player Name</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Team</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Wins</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Losses</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Ice Time</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>GAA</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>SV %</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Shutouts</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tradeReport.tradeAnalysis.statisticalAnalysis.goalieMetricsTable.map(
                            (row) => (
                              <TableRow
                                key={row.playerName}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.playerName}
                                </TableCell>
                                <TableCell align="center">{row.team}</TableCell>
                                <TableCell align="center">{row.wins}</TableCell>
                                <TableCell align="center">{row.losses}</TableCell>
                                <TableCell align="center">{row.iceTime}</TableCell>
                                <TableCell align="center">{row.goalsAgainstAverage}</TableCell>
                                <TableCell align="center">
                                  {row.savePercentage}
                                </TableCell>
                                <TableCell align="center">{row.shutouts}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>}
                  <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                   🔄 Multi-Player Dynamics
                  </Typography>
                  <Typography my={2}>
                    {tradeReport.tradeAnalysis.multiPlayerDynamics.impactOnRoster}
                  </Typography>
                  <Typography my={2}>
                    {tradeReport.tradeAnalysis.multiPlayerDynamics.positionalBalance}
                  </Typography>
                  <Typography my={2}>
                    {tradeReport.tradeAnalysis.multiPlayerDynamics.categoryCoverage}
                  </Typography>
                  <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                    ❌ X-Factor Dimension
                  </Typography>
                  <Box mt={2} />
                  <XFactorDimension xFactorDimensions={tradeReport.tradeAnalysis.xFactorDimension} />
                  <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                   💭 Comparative Analysis
                  </Typography>
                  <ComparativeAnalysis comparativeAnalysis={tradeReport.tradeAnalysis.comparativeAnalysis} />
                  <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                   🏒 Yahoo Fantasy Context
                  </Typography>
                  <Typography my={2}>
                    {tradeReport.tradeAnalysis.yahooFantasyContext.information}
                  </Typography>
                  <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                   🏆 Trade Player Scores
                  </Typography>
                  <Typography my={2}>
                    The following table shows the player scores based on advanced performance trends, fantasy points, historical reliability, league settings fit, and x-factor intangibles.
                  </Typography>
                  <TradeAnalysis pointsTable={tradeReport.tradeAnalysis.scoringSystem.pointsTable} />
                  <Typography fontWeight={700} color="textPrimary" sx={{ mt: 2 }}>
                   📚 Summary
                  </Typography>
                  <Typography my={2}>
                    {tradeReport.tradeAnalysis.finalDetermination.summary}
                  </Typography>
                  <Box mt={4} display="flex" justifyContent="center">
                    <Button
                      sx={{ p: 1 }}
                      variant="outlined"
                      color="success"
                      onClick={() => {
                        setTradeAnalyzerStep(TradeAnalyzerSteps.SelectPlayers)
                      }}
                    >
                      <SwapHorizRounded />
                      <Box ml={1} />
                      Start a New Trade
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Box>)}
      {/* Trade Suggestions */}
      {value === TradeEdgeBottomNavigationTabs.TradeSuggestions && (
        <Box p={2}>
          <Paper elevation={3} sx={{ p: { xs: 1, md: 2 } }}>
            <Box>
              <TradeSuggestions />
            </Box>
          </Paper>
        </Box>
      )}
      <Box sx={{ pt: 1, pb: 8 }} ref={ref}>
        <CssBaseline />
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(_event, newValue: number) => {
              setValue(newValue)
              handleUpdateWithCallback(TradeEdgeBottomNavigationTabs.TradeEdge === newValue ? 'trade-edge' : 'trade-suggestions')
            }}
          >
            <BottomNavigationAction label="Trade Edge" icon={<AutoMode />} />
            <BottomNavigationAction label="Trade Suggestions" icon={<AutoAwesomeSharp />} />
          </BottomNavigation>
        </Paper>
      </Box>
    </PageBase>
  )
}

export interface TradeEdgeAiInfoDialogProps {
  open: boolean
  onClose: () => void
}

const TradeEdgeAiInfoDialog: React.FC<TradeEdgeAiInfoDialogProps> = ({ open, onClose }) => {
  const handleClose = (): void => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>How Trade Edge Works</DialogTitle>
      <DialogContent>
        <Typography>
          Trade Edge is a powerful tool that helps you analyze and optimize your fantasy hockey trades.
          It uses advanced algorithms to analyze player statistics, performance trends, and x-factor intangibles to provide you with a comprehensive trade analysis report.
        </Typography>
        <Box my={2} />
        <Typography>
          To get started, select the players you want to trade and the players you want to receive. Click the Analyze Trade button to generate a trade analysis report.
        </Typography>
        <Box my={2} />
        <Typography>
          You can also remove players from the trade or switch them between the two teams. There is no limit to the number of players you can trade, but we recommend keeping it simple with 1-3 players per side.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='text' color='inherit'>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(TradeEdge)
