import React from 'react'
import { Box, Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Skeleton, styled, Tooltip, Typography } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import CenterBox from 'components/helpers/CenterBox'
import { type ConnectedYahooFantasyPlayer, type ConnectedYahooFantasyTeam } from 'features/fantasy-team/models/yahooConnectedTeam'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import { useNavigate } from 'react-router'
import PageBase from 'components/page-title/PageBase'
import { PlayerImage } from 'components/shared-components/PlayerImage'
import MyFantasyPlayerStatistics from './MyFantasyPlayerStatistics'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import MyFantasyGoalieStatistics from './MyFantasyGoalieStatistics'
import { Add, Group, HealthAndSafety, Home, LocalHospital } from '@mui/icons-material'
import TeamImage from 'components/shared-components/TeamImage'
import LazyImage from 'components/shared-components/LazyImage'

const DisabledPlug = styled(ElectricalServicesIcon)({
  color: 'info',
  fontSize: 'calc(1dvw + 64px)'
})

const MyFantasyTeam: React.FC = React.memo((): JSX.Element => {
  useDocumentTitle('FantaHockey | My Fantasy Team')
  const { openSnackbar } = useSnackbar()
  const [isPlayerStatsDrawerOpen, setIsPlayerStatsDrawerOpen] = React.useState<boolean>(false)
  const [isGoalieStatsDrawerOpen, setIsGoalieStatsDrawerOpen] = React.useState<boolean>(false)
  const [fantasyTeam, setFantasyTeam] = React.useState<ConnectedYahooFantasyTeam>({ teamRank: undefined, teamName: undefined, players: [], goalies: [] })
  const [selectedPlayer, setSelectedPlayer] = React.useState<ConnectedYahooFantasyPlayer>({
    playerId: 0,
    name: '',
    playerHeadshotUrl: '',
    team: '',
    teamLogoUrl: '',
    position: '',
    fantasyScore: 0,
    injuryStatus: '',
    isInjured: false
  } satisfies ConnectedYahooFantasyPlayer)
  const [selectedGoalie, setSelectedGoalie] = React.useState<ConnectedYahooFantasyPlayer>({
    playerId: 0,
    name: '',
    playerHeadshotUrl: '',
    team: '',
    teamLogoUrl: '',
    position: '',
    fantasyScore: 0,
    injuryStatus: '',
    isInjured: false
  } satisfies ConnectedYahooFantasyPlayer)
  const navigate = useNavigate()

  const { data: isYahooUserConnected } = useQuery<boolean>({
    queryKey: [ApiQueryKeys.IsUserConnectedToYahooQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.IsUserConnectedToYahooQuery]()).data
    },
    initialData: false
  })

  const { isError: isErrorFetchingTeam, isFetching: isFetchingYahooTeam, data: yahooFantasyTeam } = useQuery<ConnectedYahooFantasyTeam>({
    queryKey: [ApiQueryKeys.GetConnectedYahooFantasyTeamQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetConnectedYahooFantasyTeamQuery]()).data
    },
    initialData: { teamRank: undefined, teamName: undefined, players: [], goalies: [] }
  })

  React.useEffect(() => {
    const getFantasyTeam = async (): Promise<void> => {
      try {
        if (yahooFantasyTeam !== undefined) {
          setFantasyTeam(yahooFantasyTeam)
        }
      } catch (error: unknown) {
        openSnackbar('We can not fetch your Yahoo! Fantasy Team at this time.', 'error')
      }
    }
    void getFantasyTeam()
  }, [yahooFantasyTeam])

  const mapPosition = (position: string): string => {
    switch (position) {
      case 'C':
        return 'Center'
      case 'L':
        return 'Left Wing'
      case 'R':
        return 'Right Wing'
      case 'D':
        return 'Defense'
      case 'G':
        return 'Goalie'
      default:
        return ''
    }
  }

  const MyFantasyLoadingListItem = (): JSX.Element => (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Skeleton variant="circular" width={50} height={50} />
      </ListItemAvatar>
      <Box ml={1} />
      <ListItemText
        primary={
          <>
            <Skeleton variant='text' width='100%' height={20} />
            <Skeleton variant='text' width='100%' height={20} />
            <Box my={1} />
            <Skeleton variant="rectangular" width='100%' height={50} />
          </>
        }
      />
    </ListItem>
  )

  const MyFantasyTeamLoader = (): JSX.Element => (
    <Box p={1} m={1}>
      <List sx={{ bgcolor: 'background.paper', borderRadius: 2 }}>
        <Box p={1} m={1}>
          {Array.from(Array(3).keys()).map((value, index) => (
            <MyFantasyLoadingListItem key={index} />
          ))}
        </Box>
      </List>
    </Box>
  )

  const togglePlayerStatsDrawer = (newOpen: boolean): void => {
    setIsPlayerStatsDrawerOpen(newOpen)
  }

  const toggleGoalieStatsDrawer = (newOpen: boolean): void => {
    setIsGoalieStatsDrawerOpen(newOpen)
  }

  return (
    <>
      <PageBase icon={Group} title='My Fantasy Team'>
        <Box ml={2}>
          <Typography fontFamily={'Spantaran'} variant='h6' color='white'>{fantasyTeam.teamName}</Typography>
        </Box>
        <Box mt={1} />
        {isFetchingYahooTeam && (
          <MyFantasyTeamLoader />
        )}
        {!isFetchingYahooTeam && isErrorFetchingTeam && (
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <CenterBox flexDirection={'column'}>
              <Typography m={4} variant='subtitle1'>
                We can not fetch your Yahoo! Fantasy Team at this time.
              </Typography>
              <DisabledPlug color='info' fontSize={'large'} />
              <Box my={2} />
              <Button variant='outlined' color='success' onClick={() => { navigate('/home') }}>
                <Home /> <Box ml={1} /> Go Home
              </Button>
            </CenterBox>
          </Grid>
        )}
        {!isFetchingYahooTeam && !isErrorFetchingTeam && !isYahooUserConnected && (
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <CenterBox flexDirection={'column'}>
              <LazyImage src='./assets/images/Yahoo!-Logo.wine.png' alt='yahoo' height={'calc(2dvw + 64px)'} />
              <Typography variant='subtitle1' sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}>
                You have not connected your Yahoo! fantasy team.
              </Typography>
              <Typography variant='subtitle1' sx={{ display: { sm: 'block', xs: 'block', md: 'none' } }}>
                You have not connected your team.
              </Typography>
              <DisabledPlug color='info' fontSize={'large'} />
              <Box my={2} />
              <Button variant='outlined' color='success' onClick={() => { navigate('/connect-my-fantasy-team') }}>
                <Add /> Connect
              </Button>
            </CenterBox>
          </Grid>
        )}
        <MyFantasyPlayerStatistics
          onOpen={() => { togglePlayerStatsDrawer(true) }}
          open={isPlayerStatsDrawerOpen}
          player={selectedPlayer}
          onClose={() => { togglePlayerStatsDrawer(false) }}
        />
        <Grid container>
        { isYahooUserConnected && !isFetchingYahooTeam && fantasyTeam.players.length > 0 && (
          <Grid item p={1} m={1} xs={12} sm={12} md={12} xl={12}>
            <Typography fontFamily={'Spantaran'} ml={1} variant='h6'>Players</Typography>
            <Paper elevation={3}>
              <List sx={{ justifyContent: 'space-between', width: '100%', borderRadius: 2 }}>
                {fantasyTeam?.players.map((player, index: number) => (
                  <React.Fragment key={player.playerId}>
                    <ListItemButton sx={{ m: 0, p: 0 }} onClick={() => { togglePlayerStatsDrawer(true); setSelectedPlayer(player) }}>
                      <ListItem>
                        <ListItemAvatar>
                          <TeamImage src={player.teamLogoUrl} />
                          <PlayerImage src={player.playerHeadshotUrl} />
                        </ListItemAvatar>
                        <Box mr={2} />
                        <ListItemText primary={<Typography fontWeight={700}>{player.name}</Typography>}
                          secondary={<>
                            {mapPosition(player.position)}
                            <Box my={2} />
                          </>}
                        />
                         {player.isInjured &&
                            <Tooltip title={`${player.name} is injured`}>
                              <LocalHospital color='error' />
                            </Tooltip>}
                          {!player.isInjured &&
                           <Tooltip title={`${player.name} is healthy`}>
                          <HealthAndSafety color='success' />
                          </Tooltip>}
                      </ListItem>
                    </ListItemButton>
                    {fantasyTeam.players.length - 1 !== index && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
            <MyFantasyGoalieStatistics
              onOpen={() => { toggleGoalieStatsDrawer(true) }}
              open={isGoalieStatsDrawerOpen}
              goalie={selectedGoalie}
              onClose={() => { toggleGoalieStatsDrawer(false) }}
            />
              <Box my={1}/>
              <Typography fontFamily={'Spantaran'} ml={1} variant='h6'>Goalies</Typography>
              <Paper elevation={3}>
                <List sx={{ justifyContent: 'space-between', width: '100%', borderRadius: 2 }}>
                  {fantasyTeam.goalies.map((goalie, index: number) => (
                    <React.Fragment key={goalie.playerId}>
                      <ListItemButton sx={{ m: 0, p: 0 }} onClick={() => { toggleGoalieStatsDrawer(true); setSelectedGoalie(goalie) }}>
                        <ListItem>
                          <ListItemAvatar>
                            <TeamImage src={goalie.teamLogoUrl} />
                            <PlayerImage src={goalie.playerHeadshotUrl} />
                          </ListItemAvatar>
                          <Box mr={2} />
                          <ListItemText primary={<Typography fontWeight={700}>{goalie.name}</Typography>}
                            secondary={<>
                              {mapPosition(goalie.position)}
                              <Box my={2} />
                              <Box display='flex' flexDirection='row' alignItems={'center'} justifyContent={'center'}>
                              </Box>
                            </>} />
                          {goalie.isInjured &&
                            <Tooltip title={`${goalie.name} is injured`}>
                              <LocalHospital color='error' />
                            </Tooltip>}
                          {!goalie.isInjured &&
                           <Tooltip title={`${goalie.name} is healthy`}>
                          <HealthAndSafety color='success' />
                          </Tooltip>}
                        </ListItem>
                      </ListItemButton>
                      {fantasyTeam.goalies.length - 1 !== index && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
          </Grid>
        )}
        </Grid>
      </PageBase>
    </>
  )
})

MyFantasyTeam.displayName = 'MyFantasyTeam'
export default MyFantasyTeam
