import { Box, styled, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { type AxisOptions, Chart } from 'react-charts'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'
import ConnectYahooFantasyTeam from 'components/shared-components/ConnectYahooAccount'
import { useHomeData } from '../hooks/useHomeData'

const ChartContainer = styled(Box)`
  & .tickLabel {
    fill: white !important;
  }
  & .tick {
    & line {
      stroke: white !important;
      stroke-width: 0.1px;
    }
  }
`

const WeeklyFantasyTeamPerformanceDashboard = (): JSX.Element => {
  const theme = useTheme()
  const { isConnected, isLoading, weeklyPerformance } = useHomeData()

  // Memoize chart data to prevent unnecessary recalculations
  const dashboardMetricsData = useMemo(() => [
    {
      label: 'Goals',
      data: weeklyPerformance.data?.map((metric) => ({
        week: new Date(metric.key),
        value: metric.value.goals
      })) ?? []
    },
    {
      label: 'Assists',
      data: weeklyPerformance.data?.map((metric) => ({
        week: new Date(metric.key),
        value: metric.value.assists
      })) ?? []
    },
    {
      label: 'Points',
      data: weeklyPerformance.data?.map((metric) => ({
        week: new Date(metric.key),
        value: metric.value.points
      })) ?? []
    },
    {
      label: 'Power Play Goals',
      data: weeklyPerformance.data?.map((metric) => ({
        week: new Date(metric.key),
        value: metric.value.powerPlayGoals
      })) ?? []
    },
    {
      label: 'Power Play Points',
      data: weeklyPerformance.data?.map((metric) => ({
        week: new Date(metric.key),
        value: metric.value.powerPlayPoints
      })) ?? []
    }
  ], [weeklyPerformance.data])

  const primaryAxis = useMemo(
    (): AxisOptions<{ week: Date, value: number }> => ({
      getValue: data => data.week,
      styles: {
        color: theme.palette.text.primary
      }
    }),
    [theme.palette.text.primary]
  )

  const secondaryAxes = useMemo(
    (): Array<AxisOptions<{ week: Date, value: number }>> => [
      {
        getValue: data => data.value,
        styles: {
          color: theme.palette.text.primary,
          columnRuleColor: theme.palette.text.primary,
          colorRendering: 'optimizeSpeed',
          colorScheme: 'dark',
          fill: theme.palette.text.primary
        }
      }
    ],
    [theme.palette.text.primary]
  )

  if (isLoading) {
    return <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
  }

  if (!isConnected) {
    return <ConnectYahooFantasyTeam />
  }

  if (weeklyPerformance.isLoading) {
    return (
      <Box>
        <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
      </Box>
    )
  }

  return <ChartContainer sx={{ width: '100%', height: 350, minHeight: 350, maxHeight: 450 }}>
    <Chart options={{
      secondaryCursor: true,
      data: dashboardMetricsData,
      primaryAxis,
      secondaryAxes
    }} />
  </ChartContainer>
}

WeeklyFantasyTeamPerformanceDashboard.displayName = 'WeeklyFantasyTeamPerformanceDashboard'

export default React.memo(WeeklyFantasyTeamPerformanceDashboard)
