import React from 'react'
import { useDocumentTitle } from '@uidotdev/usehooks'
import PageBase from 'components/page-title/PageBase'
import HomeIcon from '@mui/icons-material/Home'
import WelcomeHomeUserModal from './components/HomeWelcomeUserModal'
import { useFantaHockeyContext } from 'providers/AuthProvider'
import HomeGridLayout from './components/HomeGridLayout'

const Home: React.FC = () => {
  useDocumentTitle('FantaHockey | Home')
  const fantaHockeyContext = useFantaHockeyContext()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (fantaHockeyContext.isFirstLogin) {
      setOpen(true)
    }
  }, [fantaHockeyContext.isFirstLogin])

  const handleCloseModal = React.useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <PageBase icon={HomeIcon} title={'Home'}>
      <WelcomeHomeUserModal open={open} onClose={handleCloseModal} />
      <HomeGridLayout />
    </PageBase>
  )
}

export default React.memo(Home)
