import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { type FantasyTeamPerformanceAnalysis } from 'features/home/models/fantasyTeamPerformanceAnalysis'
import { Box, Stack, Typography } from '@mui/material'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'
import ConnectYahooFantasyTeam from 'components/shared-components/ConnectYahooAccount'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'

const FantasyTeamPerformanceReport: React.FC = React.memo(() => {
  const store = useFantaHockeyStore()
  const intervalTimeMs = 5000

  const { data: fantasyReport } = useQuery<FantasyTeamPerformanceAnalysis>({
    queryKey: [ApiQueryKeys.GetFantasyTeamPerformanceAnalysisQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetFantasyTeamPerformanceAnalysisQuery]()).data
    },
    enabled: true,
    refetchInterval: (query) => {
      if (query.state?.data?.strengths === undefined || query.state?.data?.weaknesses === undefined || query.state?.data?.opportunities === undefined || query.state?.data?.threats === undefined || query.state?.data?.recommendations === undefined) {
        return intervalTimeMs
      } else {
        return 0
      }
    }
  })

  if (!store.isConnectedToYahooFantasy && !store.isFetchingConnectionStatus) {
    return <ConnectYahooFantasyTeam />
  }

  if (fantasyReport?.strengths === undefined || fantasyReport?.weaknesses === undefined || fantasyReport?.opportunities === undefined || fantasyReport?.threats === undefined || fantasyReport?.recommendations === undefined) {
    return <Box display='flex' flexDirection='column' alignItems='center'>
              <Box my={1} />
              <CircularContentLoader size={'36px'} thickness={4} marginBottom={'16px'} marginTop={'16px'} textAlignment={'center'} />
          </Box>
  }

  return (
    <Stack spacing={2} pt={2} pb={4}>
        <Typography variant='body1' fontWeight={700}>Strengths</Typography>
        {fantasyReport?.strengths?.map((strength, index) => (
          <Typography variant='body2' key={index}>{strength}</Typography>
        ))}
        <Typography variant="body1" fontWeight={700}>Weaknesses</Typography>
        {fantasyReport?.weaknesses?.map((weakness, index) => (
          <Typography variant='body2' key={index}>{weakness}</Typography>
        ))}
        <Typography variant="body1" fontWeight={700}>Opportunities</Typography>
        {fantasyReport?.opportunities?.map((opportunity, index) => (
          <Typography variant='body2' key={index}>{opportunity}</Typography>
        ))}
        <Typography variant="body1" fontWeight={700}>Threats</Typography>
        {fantasyReport?.threats?.map((threat, index) => (
          <Typography variant='body2' key={index}>{threat}</Typography>
        ))}
        <Typography variant="body1" fontWeight={700}>Recommendations</Typography>
        {fantasyReport?.recommendations?.map((recommendation, index) => (
          <Typography variant='body2' key={index}>{recommendation}</Typography>
        ))}
    </Stack>
  )
})

FantasyTeamPerformanceReport.displayName = 'FantasyTeamPerformanceReport'
export default FantasyTeamPerformanceReport
