import { Box, type SvgIconProps, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import DynamicIcon from 'components/helpers/DynamicIcon'

interface Props {
  title: string
  icon: React.ComponentType<SvgIconProps> | undefined
}

const AppPageTitle: React.FC<Props> = React.memo(({ title, icon }): JSX.Element => {
  return (
    <Box pl={1} ml={1} display='flex' alignItems='center' alignContent={'center'}>
      <DynamicIcon fontSizeMd={30} fontSizeSm={26} htmlColor='#fff' Icon={icon ?? undefined} />
      <Box ml={1} />
      <Typography
        fontFamily={'Spantaran'}
        variant='h4'
        sx={{
          display: {
            xl: 'block',
            lg: 'block',
            md: 'none',
            xs: 'none'
          }
        }}
      >
        {title}
      </Typography>
      <Typography
        fontFamily={'Spantaran'}
        variant='h5'
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'block',
            xs: 'block'
          }
        }}
      >
        {title}
      </Typography>
    </Box>
  )
})

AppPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any
}

AppPageTitle.displayName = 'AppPageTitle'
export default React.memo(AppPageTitle)
