import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'

interface HomeCardProps {
  title: string
  children: React.ReactNode
  minHeight?: number
  maxHeight?: number
  overflowY?: 'scroll' | 'hidden' | 'auto' | 'visible'
}

const HomeCard: React.FC<HomeCardProps> = ({
  title,
  children,
  minHeight = 425,
  maxHeight = 425,
  overflowY = 'auto'
}) => {
  return (
    <Box>
      <Typography ml={1} fontFamily={'Spantaran'} variant={'h6'}>
        {title}
      </Typography>
      <Card
        sx={{
          minHeight: { xs: 400, sm: 400, md: minHeight },
          maxHeight: { xs: 400, sm: 400, md: maxHeight },
          overflowY
        }}
      >
        <CardContent
          sx={{
            minHeight: { xs: 400, sm: 400, md: minHeight },
            maxHeight: { xs: 400, sm: 400, md: maxHeight }
          }}
        >
          {children}
        </CardContent>
      </Card>
    </Box>
  )
}

export default React.memo(HomeCard)
