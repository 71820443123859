import React from 'react'
import { Box, Grid2 } from '@mui/material'
import HomeCard from './HomeCard'
import TopFantasyPlayers from './TopFantasyPlayers'
import WeeklyFantasyTeamPerformanceDashboard from './WeeklyFantasyTeamPerformanceDashboard'
import FantasyTeamPerformanceReport from 'components/fantasy-team-performance-report/FantasyTeamPerformanceReport'
import LiveGameScores from 'components/live-game-scores/LiveGameScores'
import useFantaHockeyStore from 'hooks/store/FantaHockeyStateStore'

const HomeGridLayout: React.FC = () => {
  const store = useFantaHockeyStore()

  return (
    <Box p={1} m={1}>
      <Grid2 spacing={2} container>
        <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4 }}>
          <HomeCard
            title="Fantasy Team Report"
            overflowY={store.isConnectedToYahooFantasy ? 'scroll' : 'hidden'}
            minHeight={300} maxHeight={425}
          >
            <FantasyTeamPerformanceReport />
          </HomeCard>
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
          <HomeCard title="Top Fantasy Players" minHeight={425} maxHeight={425} overflowY='hidden'
          >
            <TopFantasyPlayers />
          </HomeCard>
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
          <HomeCard title="Weekly Team Performance" minHeight={425} maxHeight={425} overflowY='hidden'>
            <WeeklyFantasyTeamPerformanceDashboard />
          </HomeCard>
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Box>
            <HomeCard title="Live Scores" minHeight={300} maxHeight={500}>
              <LiveGameScores numberOfGames={3} setTitle={false} setIcon={false} />
            </HomeCard>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default React.memo(HomeGridLayout)
